















































import { Component, Vue, Prop } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as rpt from "@/api/examProjectReport";
import { CommentParams, ScoreStatistics } from "@/classes/exam-comment";
import * as _ from "lodash";

interface ExamPaperScoreItem extends ScoreStatistics {
  examPaperId: string;
  examPaperName: string;
}

@Component({
  name: "StatisticsExamPaper",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  @Prop({ default: () => new CommentParams() }) private params!: CommentParams;

  private title: string = "试卷与成绩概况";

  private tableData: ExamPaperScoreItem[] = [];

  private async __init() {
    if (!this.params.examProjectId) {
      return;
    }
    const { data } = await rpt.getExamPaperScoreReport(this.params);
    console.log(`${JSON.stringify(data)}`);
    this.tableData = data.items.map((item: ExamPaperScoreItem) => {
      item.scoringRate = Math.round(item.scoringRate * 10000) / 100.0;
      item.fullScoreRate = Math.round(item.fullScoreRate * 10000) / 100.0;
      item.excellentRate = Math.round(item.excellentRate * 10000) / 100.0;
      item.goodScoreRate = Math.round(item.goodScoreRate * 10000) / 100.0;
      item.lowScoreRate = Math.round(item.lowScoreRate * 10000) / 100.0;
      item.distinction = Math.round(item.distinction * 10000) / 100.0;

      return item;
    });
  }

  mounted() {
    console.log(
      `StatisticsExamPaper mounted: ${JSON.stringify(this.params)} ...`
    );
    this.__init();
  }
}
